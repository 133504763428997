// components/SlideOut/SlideOutMenu.jsx

import React, { useCallback, useState, useEffect } from "react";
import { useViewport } from "../../context/ViewportContext";
import { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link } from "react-router-dom";
import SlideOut from "../SlideOut/SlideOut";
import SlideOutLogoContainer from "./Logo/SlideOutLogoContainer";
import useStore from "../../stores/store";
import SlideOutDetailsContainer from "./Details/SlideOutDetailsContainer";

const SlideOutMenu = ({
  items,
  selectedItem,
  onItemSelect,
  onBackClick,
  isLoading,
  renderLogo,
  isCollapsed,
  onCollapse,
  basePath,
}) => {
  const { theme, updateSlideOut } = useStore();
  const viewport = useViewport();
  const [hoveredItem, setHoveredItem] = useState(null);
  const [internalIsCollapsed, setInternalIsCollapsed] = useState(isCollapsed);

  useEffect(() => {
    if (viewport.isLandscape) {
      setInternalIsCollapsed(false);
    } else {
      setInternalIsCollapsed(isCollapsed);
    }
  }, [isCollapsed, viewport.isLandscape]);

  useEffect(() => {
    updateSlideOut(true, internalIsCollapsed);

    return () => {
      updateSlideOut(false, true);
    };
  }, [internalIsCollapsed, updateSlideOut]);

  const handleItemClick = useCallback(
    (itemId) => {
      onItemSelect(itemId);
      // Collapse menu in portrait mode after item selection
      if (!viewport.isLandscape) {
        setInternalIsCollapsed(true);
        onCollapse(true);
      }
    },
    [onItemSelect, viewport.isLandscape, onCollapse]
  );

  const handleItemHover = useCallback((itemId) => {
    setHoveredItem(itemId);
  }, []);

  const handleItemLeave = useCallback(() => {
    setHoveredItem(null);
  }, []);

  const handleCollapse = useCallback(() => {
    if (!viewport.isLandscape) {
      const newCollapsedState = !internalIsCollapsed;
      setInternalIsCollapsed(newCollapsedState);
      onCollapse(newCollapsedState);
      updateSlideOut(true, newCollapsedState);
    }
  }, [internalIsCollapsed, onCollapse, viewport.isLandscape, updateSlideOut]);

  const renderMenuItem = useCallback(
    (item) => {
      const isSelected = item.id === selectedItem;
      const itemPath = `${basePath}/${item.id}`;

      return (
        <Link
          key={item.id}
          to={itemPath}
          onClick={() => handleItemClick(item.id)}
          onMouseEnter={() => handleItemHover(item.id)}
          onMouseLeave={handleItemLeave}
          className={isSelected ? "active" : ""}
        >
          {item.title}
        </Link>
      );
    },
    [selectedItem, basePath, handleItemClick, handleItemHover, handleItemLeave]
  );

  const renderContent = useCallback(
    (props) => (
      <>
        <SlideOutLogoContainer
          items={items}
          selectedItem={selectedItem}
          hoveredItem={hoveredItem}
          onItemClick={handleItemClick}
          onItemHover={handleItemHover}
          onItemLeave={handleItemLeave}
          renderLogo={renderLogo}
          viewport={viewport}
        />
        <SlideOutDetailsContainer
          items={items}
          selectedItem={selectedItem}
          hoveredItem={hoveredItem}
          onItemClick={handleItemClick}
          onItemHover={handleItemHover}
          onItemLeave={handleItemLeave}
          theme={theme}
          viewport={viewport}
          isCollapsed={internalIsCollapsed}
        />
      </>
    ),
    [
      items,
      selectedItem,
      hoveredItem,
      handleItemClick,
      handleItemHover,
      handleItemLeave,
      renderLogo,
      viewport,
      theme,
      internalIsCollapsed,
    ]
  );

  return (
    <SkeletonTheme
      baseColor={theme.primaryColor}
      highlightColor={theme.iconColorSecondary}
    >
      <SlideOut
        isCollapsed={internalIsCollapsed}
        onCollapse={handleCollapse}
        viewport={viewport}
        selectedItemId={selectedItem}
        onItemSelect={handleItemClick}
        onBackClick={onBackClick}
        isLoading={isLoading}
        showCloseButton={false}
      >
        {renderContent}
      </SlideOut>
    </SkeletonTheme>
  );
};

export default SlideOutMenu;
