// components/Menu/Nav/Nav.jsx

import React from "react";
import { motion } from "framer-motion";
import { links, footerLinks } from "./data";
import { perspective, slideIn } from "./anim.js";
import {
  NavWrapper,
  LinkContainer,
  NavBody,
  NavFooter,
  StyledLink,
  FooterLink,
} from "./Nav.styled";
import useStore from "../../../stores/store.js";

export default function Nav({ onLinkClick, $viewport }) {
  const { startTransition } = useStore((state) => ({
    startTransition: state.startTransition,
  }));

  const handleLinkClick = (e, link) => {
    e.preventDefault();
    if (link.scene) {
      startTransition(link.scene, link.transition);
    }
    onLinkClick();
    // Small delay to allow the menu animation to complete
    setTimeout(() => {
      window.location.href = `#${link.href}`;
    }, 50);
  };

  return (
    <NavWrapper $viewport={$viewport}>
      <NavBody $viewport={$viewport}>
        {links.map((link, i) => {
          return (
            <LinkContainer key={`b_${i}`} $viewport={$viewport}>
              <motion.div
                custom={i}
                variants={perspective}
                initial="initial"
                animate="enter"
                exit="exit"
              >
                <StyledLink
                  to={link.href}
                  onClick={(e) => handleLinkClick(e, link)}
                  $viewport={$viewport}
                >
                  {link.title}
                </StyledLink>
              </motion.div>
            </LinkContainer>
          );
        })}
      </NavBody>
      <NavFooter $viewport={$viewport}>
        {footerLinks.map((link, i) => {
          const { title, href } = link;
          return (
            <FooterLink
              href={href}
              variants={slideIn}
              custom={i}
              initial="initial"
              animate="enter"
              exit="exit"
              key={`f_${i}`}
              onClick={onLinkClick}
              $viewport={$viewport}
              target="_blank"
              rel="noopener noreferrer"
            >
              {title}
            </FooterLink>
          );
        })}
      </NavFooter>
    </NavWrapper>
  );
}
