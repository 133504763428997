import styled from "styled-components";
import { breakpoints } from "../../../styles/breakpoints";

export const ExperienceUIOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  z-index: 980;
`;

export const ExperienceUIWrapper = styled.div`
  display: grid;
  z-index: 990;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  max-height: calc(var(--vh, 1vh) * 100);

  ${({ $viewport }) => {
    if ($viewport.width <= breakpoints.mobileS) {
      return `
        grid-template-columns: 3rem 1fr;
        grid-template-rows: 3rem 1fr 3rem;
        width: 95%;
        height: calc(var(--vh, 1vh) * ${$viewport.isLandscape ? "90" : "90"});
        ${$viewport.isLandscape ? "grid-template-rows: 2rem 1fr 2rem;" : ""}
      `;
    }
    if ($viewport.width <= breakpoints.mobileM) {
      return `
        grid-template-columns: 3rem 1fr;
        grid-template-rows: 3rem 1fr 3rem;
        width: 95%;
        height: calc(var(--vh, 1vh) * ${$viewport.isLandscape ? "92" : "96"});
        ${$viewport.isLandscape ? "grid-template-rows: 2.5rem 1fr 2.5rem;" : ""}
      `;
    }
    if ($viewport.width <= breakpoints.mobileL) {
      return `
        grid-template-columns: 3rem 1fr;
        grid-template-rows: 3rem 1fr 3rem;
        width: 96%;
        height: calc(var(--vh, 1vh) * ${$viewport.isLandscape ? "94" : "97"});
        ${$viewport.isLandscape ? "grid-template-rows: 2.75rem 1fr 2.75rem;" : ""}
      `;
    }
    if ($viewport.width <= breakpoints.tabletS) {
      return `
        grid-template-columns: ${$viewport.isLandscape ? "3.5rem 1fr" : "3.5rem 1fr"};
        grid-template-rows: ${$viewport.isLandscape ? "2.75rem 1fr 2.75rem" : "3.5rem 1fr 3.5rem"};
        width: 96%;
        height: calc(var(--vh, 1vh) * ${$viewport.isLandscape ? "95" : "97"});
      `;
    }
    if ($viewport.width <= breakpoints.tabletM) {
      return `
        grid-template-columns: ${$viewport.isLandscape ? "3.5rem 1fr" : "3.5rem 1fr"};
        grid-template-rows: ${$viewport.isLandscape ? "2.75rem 1fr 2.75rem" : "3.5rem 1fr 3.5rem"};
        width: 96%;
        height: calc(var(--vh, 1vh) * ${$viewport.isLandscape ? "95" : "97"});
      `;
    }
    if ($viewport.width <= breakpoints.tabletL) {
      return `
        grid-template-columns: ${$viewport.isLandscape ? "3.5rem 1fr" : "3.5rem 1fr"};
        grid-template-rows: ${$viewport.isLandscape ? "2.75rem 1fr 2.75rem" : "3.5rem 1fr 3.5rem"};
        width: 96%;
        height: calc(var(--vh, 1vh) * ${$viewport.isLandscape ? "95" : "97"});
      `;
    }
    if ($viewport.width <= breakpoints.laptopS) {
      return `
        grid-template-columns: ${$viewport.isLandscape ? "5.25rem 1fr" : "5.25rem 1fr"};
        grid-template-rows: ${$viewport.isLandscape ? "5.25rem 1fr 5.25rem" : "5.25rem 1fr 5.25rem"};
        width: 95%;
        height: calc(var(--vh, 1vh) * ${$viewport.isLandscape ? "95" : "95"});
      `;
    }
    if ($viewport.width <= breakpoints.laptopM) {
      return `
        grid-template-columns: ${$viewport.isLandscape ? "5.25rem 1fr" : "5.25rem 1fr"};
        grid-template-rows: ${$viewport.isLandscape ? "5.25rem 1fr 5.25rem" : "5.25rem 1fr 5.25rem"};
        width: 95%;
        height: calc(var(--vh, 1vh) * ${$viewport.isLandscape ? "95" : "95"});
      `;
    }
    return `
      /* Styles for Laptop and above */
      grid-template-columns: 5.25rem 1fr;
      grid-template-rows: 5.25rem 1fr 5.25rem;
      width: 95%;
      max-width: 1920px;
      height: calc(var(--vh, 1vh) * 90);
    `;
  }}
`;

export const ExperienceUIContent = styled.div`
  display: grid;
  grid-column: 1/-1;
  grid-row: 1/-1;
  height: 100%;
`;
