import styled from "styled-components";
import { hexToRgba } from "../../../utils/hexToRGBA";
import { media } from "../../../styles/breakpoints";

export const SlideOutLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
  grid-column: 1;
  grid-row: 2;

  ${media.down("tabletL")`
    padding: 0.5rem 0.25rem;
  `}
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, $isActive, $isHovered }) =>
    $isActive
      ? theme.accentColorPrimary
      : $isHovered
        ? theme.tertiaryColor
        : theme.primaryColor};
  border-radius: 5px;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  margin: 0.625rem auto;
  transition: background-color 0.3s ease;
  cursor: pointer;
  font-size: 0.7rem;
  text-align: center;
  color: ${({ theme }) => theme.textColorLight};
  text-transform: uppercase;

  ${media.down("mobileL")`
    && {
      height: 2rem;
      width: 2rem;
      padding: 0.25rem;
      margin: 0.5rem auto;
    }
  `}

  ${media.between("mobileL", "tabletL")`
    && {
      height: 2.5rem;
      width: 2.5rem;
      padding: 0.25rem;
      margin: 0.5rem auto;
    }
  `}

  @media (orientation: landscape) {
    ${media.down("tabletL")`
      && {
        height: 2rem;
        width: 2rem;
        padding: 0.125rem;
        margin: 0.25rem auto;
      }
    `}
  }
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem 1rem;
  overflow-y: hidden;
  grid-column: 2;
  grid-row: 2;
  opacity: ${({ $isCollapsed }) => ($isCollapsed ? 0 : 1)};
  transition: opacity 0.2s ease-in-out;
  height: fit-content;

  ${media.down("tabletL")`
    padding: 0.5rem;
  `}

  @media (orientation: landscape) {
    ${media.down("tabletM")`
      padding: 0.25rem 0.5rem 0.5rem 0.5rem;
    `}
  }
`;

export const CategoryHeader = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme, $isActive, $isHovered }) =>
    $isActive
      ? theme.accentColorPrimary
      : $isHovered
        ? theme.tertiaryColor
        : theme.primaryColor};
  padding: 1.25rem 1rem;
  margin: 0.575rem 0;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  cursor: pointer;
  justify-content: center;

  h2 {
    margin: 0;
    color: ${({ theme }) => theme.textColorLight};
    font-size: 0.9rem;
  }

  ${media.down("mobileL")`
    && {
      height: 2rem;
      padding: 0.25rem 0 0.25rem 0.75rem;
      margin: 0.5rem 0;
    }
  `}

  ${media.between("mobileL", "tabletL")`
    && {
      height: 2.5rem;
      padding: 0.25rem 0 0.25rem 0.75rem;
      margin: 0.5rem 0;
    }
  `}
`;

export const ControlPanel = styled.div`
  background-color: ${({ theme }) => theme.tertiaryColor};
  padding: 1rem;
  border-radius: 5px;
  flex: 1;
  overflow: auto;

  ${media.down("tabletL")`
    padding: 0.75rem;
  `}
`;

export const Control = styled.div`
  margin-bottom: 1rem;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const Label = styled.label`
  display: block;
  font-size: 0.75rem;
  margin-bottom: 0.25rem;
  color: ${({ theme }) => theme.textColorLight};
`;

export const Value = styled.span`
  float: right;
  color: ${({ theme }) => theme.textColorSecondary};
`;

export const Slider = styled.input`
  width: 100%;
  margin: 0;

  &[type="range"] {
    -webkit-appearance: none;
    background: ${({ theme }) => hexToRgba(theme.primaryColor, 0.5)};
    border-radius: 3px;
    height: 3px;
    outline: none;
  }
  /* Larger touch target */
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: ${({ theme }) => theme.accentColorPrimary};
    cursor: pointer;
    border: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  /* Firefox */
  &::-moz-range-thumb {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: ${({ theme }) => theme.accentColorPrimary};
    cursor: pointer;
    border: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
`;

export const RegenerateButton = styled.button`
  background: ${({ theme }) => theme.primaryColor};
  color: ${({ theme }) => theme.textColorLight};
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  cursor: pointer;
  width: 100%;

  &:hover {
    background: ${({ theme }) => theme.secondaryColor};
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
