/**
 * slideOutSlice.js
 *
 * This file defines a Zustand store slice for managing the slide-out component's state.
 */

import { create } from "zustand";

/**
 * Creates the slide-out slice for the Zustand store
 * @param {Function} set - Zustand's set function to update the store
 * @param {Function} get - Zustand's get function to access the store state
 * @returns {Object} The slide-out slice with state and actions
 */
const createSlideOutSlice = (set, get) => ({
  // State
  isPresent: false,
  isCollapsed: false,
  hoveredItem: null,
  selectedItem: null,
  selectedTab: "Highlights",
  shouldCollapseMenu: true,
  viewport: { isLandscape: false },

  // Actions
  /**
   * Updates both the presence and collapse state of the slide-out
   * @param {boolean} isPresent - Whether the slide-out should be present in the DOM
   * @param {boolean} isCollapsed - Whether the slide-out should be collapsed
   */
  updateSlideOut: (isPresent, isCollapsed) => set({ isPresent, isCollapsed }),

  /**
   * Opens the slide-out
   */
  openSlideOut: () => set({ isPresent: true, isCollapsed: false }),

  /**
   * Closes the slide-out
   */
  closeSlideOut: () => set({ isPresent: true, isCollapsed: true }),

  /**
   * Removes the slide-out from the DOM
   */
  removeSlideOut: () => set({ isPresent: false, isCollapsed: true }),

  /**
   * Toggles the slide-out state
   */
  toggleCollapse: () => set((state) => ({ isCollapsed: !state.isCollapsed })),

  /**
   * Handles hover state
   */
  setHoveredItem: (item) => set({ hoveredItem: item }),

  /**
   * Sets whether or not the slide-out should collapse
   */
  setShouldCollapseMenu: (shouldCollapse) =>
    set({ shouldCollapseMenu: shouldCollapse }),

  // Actions for Resume-specific needs
  setSelectedItem: (item) => set({ selectedItem: item }),
  setSelectedTab: (tab) => set({ selectedTab: tab }),
  resetSlideOutState: () =>
    set({
      isCollapsed: true,
      selectedItem: null,
      selectedTab: "Highlights",
    }),
});

export default createSlideOutSlice;

/**
 * Create a standalone Zustand store with the slide-out slice
 */
export const useSlideOutStore = create(createSlideOutSlice);

// Usage example:
// import { useSlideOutStore } from './createSlideOutSlice';
//
// function MyComponent() {
//   const { isCollapsed, openSlideOut, closeSlideOut } = useSlideOutStore();
//
//   return (
//     <div>
//       <button onClick={isCollapsed ? openSlideOut : closeSlideOut}>
//         {isCollapsed ? 'Open' : 'Close'} Slide-out
//       </button>
//     </div>
//   );
// }
