// constants/transitions.js
export const TransitionType = {
  FADE: "FADE",
  SLIDE_LEFT: "SLIDE_LEFT",
  SLIDE_RIGHT: "SLIDE_RIGHT",
  SLIDE_UP: "SLIDE_UP",
  SLIDE_DOWN: "SLIDE_DOWN",
  SPIRAL: "SPIRAL",
  HEX: "HEX",
};

// Map transition names to shader values
export const getShaderTypeValue = (type) => {
  const types = {
    [TransitionType.FADE]: 0,
    [TransitionType.SLIDE_LEFT]: 1,
    [TransitionType.SLIDE_RIGHT]: 2,
    [TransitionType.SLIDE_UP]: 3,
    [TransitionType.SLIDE_DOWN]: 4,
    [TransitionType.SPIRAL]: 4,
    [TransitionType.HEX]: 5,
  };
  console.log("Getting shader type for:", type, "->", types[type] || 0);
  return types[type] || 0;
};

export const TRANSITION_TIMING = {
  DURATION: 1000,
  SPIRAL_DURATION: 1500,
  HEX_DURATION: 2000,
};
