import styled from "styled-components";
import { hexToRgba } from "../../utils/hexToRGBA";
import { media } from "../../styles/breakpoints";

export const SlideOutLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
  grid-column: 1;
  grid-row: 2;

  ${media.down("tabletL")`
    padding: 0.5rem 0.25rem;
  `}
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.textColorLight};
  background-color: ${({ theme, $isActive, $isHovered }) =>
    $isActive
      ? theme.accentColorPrimary
      : $isHovered
        ? theme.tertiaryColor
        : theme.primaryColor};
  border-radius: 5px;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  margin: 0.625rem auto;
  transition: background-color 0.3s ease;
  cursor: pointer;
  font-size: 0.875rem;

  ${media.down("mobileL")`
    height: 2rem;
    width: 2rem;
    padding: 0.25rem;
    margin: 0.25rem auto;
    font-size: 0.75rem;
  `}

  ${media.between("mobileL", "tabletL")`
    height: 2rem;
    width: 2rem;
    padding: 0.375rem;
    margin: 0.375rem auto;
    font-size: 0.8rem;
  `}

  @media (orientation: landscape) {
    ${media.down("tabletL")`
      height: 2rem;
      width: 2rem;
      padding: 0.25rem;
      margin: 0.25rem auto;
    `}
  }
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem 1rem;
  overflow-y: auto;
  grid-column: 2;
  grid-row: 2;
  opacity: ${({ $isCollapsed }) => ($isCollapsed ? 0 : 1)};
  transition: opacity 0.2s ease-in-out;
  height: 100%;

  ${media.down("tabletL")`
    padding: 0.25rem 0.5rem;
  `}

  ${media.down("mobileL")`
    padding: 0.25rem 0.5rem;
  `}

  h2 {
    font-size: 1.1rem;
    margin: 0 0 0.75rem;

    ${media.down("mobileL")`
      font-size: 1rem;
      margin: 0 0 0.5rem;
    `}
  }
`;

export const MaterialControl = styled.div`
  margin: 0.575rem 0 0.85rem 0;
  background: ${({ theme }) => theme.bodyBgPrimary};
  border: 1px solid ${({ theme }) => theme.primaryColor};
  border-radius: 6px;
  overflow: hidden;
  &:last-child {
    margin-bottom: 0;
  }

  ${media.down("tabletL")`
    margin: 0.625rem 0 ;
  `}

  ${media.down("mobileL")`
    margin: 0.525rem 0 ;
  `}
`;

export const MaterialHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 1rem;
  cursor: pointer;
  color: ${({ theme }) => theme.textColorLight};
  background: ${({ theme }) => theme.secondaryColor};
  user-select: none;

  &:hover {
    background: ${({ theme }) => theme.tertiaryColor};
  }

  ${media.down("tabletL")`
    padding: 0.75rem 0.75rem;
  `}

  ${media.down("mobileL")`
    padding: 0.55rem 0.5rem;
  `}
`;

export const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const Arrow = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(${({ $isOpen }) => ($isOpen ? "180deg" : "0deg")});
  transition: transform 0.2s ease;

  svg {
    width: 12px;
    height: 12px;
  }
`;

export const MaterialName = styled.div`
  font-weight: 500;
  font-size: 0.875rem;
`;

export const MaterialContent = styled.div`
  padding: 0.75rem;
  border-top: 1px solid ${({ theme }) => theme.borderColorPrimary};
  display: ${({ $isOpen }) => ($isOpen ? "block" : "none")};
`;

export const SliderGroup = styled.div`
  margin-bottom: 0.5rem;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const SliderLabel = styled.label`
  display: block;
  font-size: 0.75rem;
  margin-bottom: 0.25rem;
  color: ${({ theme }) => theme.textColorPrimary};
`;

export const Slider = styled.input`
  width: 100%;
  margin: 0;

  &[type="range"] {
    -webkit-appearance: none;
    background: ${({ theme }) => hexToRgba(theme.primaryColor, 0.5)};
    border-radius: 3px;
    height: 3px;
    outline: none;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: ${({ theme }) => theme.accentColorPrimary};
      cursor: pointer;
      border: none;
    }
  }
`;

export const ColorInput = styled.input`
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  &::-webkit-color-swatch {
    border: none;
    border-radius: 4px;
  }
`;

export const Content = styled.div`
  border-radius: 8px;
  color: ${(props) => props.theme.textColorPrimary};
  z-index: 900;

  h2 {
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0 0 1rem;
    color: ${({ theme }) => theme.textColorPrimary};
  }

  p {
    margin-bottom: 1rem;
  }
`;
