import React from "react";
import styled from "styled-components";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { media } from "../../styles/breakpoints";
import SlideOut from "../../components/SlideOut/SlideOut";

const HexSlideOutContainer = styled.div`
  .collapse-button {
    display: none;
  }

  .slide-out {
    position: relative;
  }
`;

const HexCollapseButton = styled.button`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.primaryColor};
  color: ${({ theme }) => theme.textColorLight};
  border: none;
  border-radius: ${({ $isCollapsed }) =>
    $isCollapsed ? "0 5px 5px 0" : "5px"};
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 960;
  right: ${({ $isCollapsed }) => ($isCollapsed ? "-35px" : "1rem")};
  bottom: 0.25rem;
  transform: translateY(-50%);
  width: 35px;
  height: 35px;
  opacity: 1;

  ${media.down("tabletS")`
    width: 28px;
    height: 28px;
    right: ${({ $isCollapsed }) => ($isCollapsed ? "-28px" : "0.75rem")};
  `}

  ${media.down("mobileL")`
    width: 24px;
    height: 24px;
    right: ${({ $isCollapsed }) => ($isCollapsed ? "-24px" : "0.5rem")};
  `}

  &:hover {
    background-color: ${({ theme }) => theme.secondaryColor};
  }
`;

const HexSlideOut = ({
  children,
  theme,
  isCollapsed,
  onCollapse,
  selectedItem,
  onItemSelect,
  onBackClick,
  showCloseButton,
}) => {
  const wrappedChildren = (props) => {
    const content = typeof children === "function" ? children(props) : children;

    return (
      <>
        {content}
        <HexCollapseButton
          theme={theme}
          $isCollapsed={isCollapsed}
          onClick={onCollapse}
          className="hex-collapse-button"
        >
          {isCollapsed ? <ChevronRight size={16} /> : <ChevronLeft size={16} />}
        </HexCollapseButton>
      </>
    );
  };

  return (
    <HexSlideOutContainer>
      <SlideOut
        theme={theme}
        isCollapsed={isCollapsed}
        onCollapse={onCollapse}
        selectedItem={selectedItem}
        onItemSelect={onItemSelect}
        onBackClick={onBackClick}
        showCloseButton={showCloseButton}
      >
        {wrappedChildren}
      </SlideOut>
    </HexSlideOutContainer>
  );
};

export default HexSlideOut;
