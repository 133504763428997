import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
    console.error("Caught an error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // If this is within a React Three Fiber context, render a 3D error indicator
      if (this.props.r3f) {
        return (
          <group>
            <mesh position={[0, 0, 0]}>
              <boxGeometry args={[1, 1, 1]} />
              <meshStandardMaterial color="red" />
            </mesh>
            {/* Optional - only if you want to show error text in 3D space */}
            {this.props.showErrorText && (
              <group position={[0, 1.5, 0]}>
                <mesh>
                  <textGeometry args={["Error"]} center />
                  <meshStandardMaterial color="white" />
                </mesh>
              </group>
            )}
          </group>
        );
      }

      // Standard React fallback UI
      return (
        <div>
          <h1>Something went wrong.</h1>
          <details style={{ whiteSpace: "pre-wrap" }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo && this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

// Usage examples:
//
// Regular React context:
// <ErrorBoundary>
//   <MyComponent />
// </ErrorBoundary>
//
// React Three Fiber context:
// <ErrorBoundary r3f>
//   <MyR3FComponent />
// </ErrorBoundary>
//
// With 3D error text:
// <ErrorBoundary r3f showErrorText>
//   <MyR3FComponent />
// </ErrorBoundary>
