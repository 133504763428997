import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { media } from "../../styles/breakpoints";
import { typography } from "../../styles/abstracts/typography";

export const buttonStyles = css`
  background: ${({ theme, $disabled }) =>
    $disabled ? theme.disabledColor : theme.secondaryColor};
  color: ${({ theme, $disabled }) =>
    $disabled ? theme.disabledTextColor : theme.textColorLight};
  border: none;
  border-radius: 10px;
  padding: 1.25rem 2.5rem;
  font-size: ${typography.fontSizeSmall};
  font-weight: 500;
  cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: all 0.3s ease;
  max-height: 2.5rem;
  text-decoration: none;
  line-height: 1;
  width: fit-content;
  pointer-events: auto;

  &:hover {
    background: ${({ theme, $disabled }) =>
      $disabled ? theme.disabledColor : theme.accentColorPrimary};
    transform: ${({ $disabled }) => ($disabled ? "none" : "translateY(-5px)")};
  }

  ${media.down("mobileS")`
  `}

  ${media.between("mobileS", "mobileM")`
  `}

  ${media.between("mobileM", "mobileL")`
  `}

  ${media.between("mobileL", "tabletS")`
  `}

  ${media.between("tabletS", "tabletM")`
  `}

  ${media.between("tabletM", "tabletL")`
  `}

  ${media.between("tabletL", "laptopS")`
  `}

  ${media.up("laptopS")`
  `}
`;

export const StyledButton = styled.button`
  ${buttonStyles}
`;

export const StyledLink = styled(Link)`
  && {
    ${buttonStyles}
    text-decoration: none;
    color: ${({ theme, $disabled }) =>
      $disabled ? theme.disabledTextColor : theme.textColorLight};

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
      color: ${({ theme, $disabled }) =>
        $disabled ? theme.disabledTextColor : theme.textColorPrimary};
    }
  }
`;

export const StyledAnchor = styled.a`
  ${buttonStyles}
  text-decoration: none;
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    color: ${({ theme, $disabled }) =>
      $disabled ? theme.disabledTextColor : theme.textColorLight};
  }
`;
