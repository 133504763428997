import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { MeshStandardMaterial, Color } from "three";

const MATERIAL_TYPES = {
  LEGS: "legs",
  FABRIC: "fabric",
  CUSHION: "cushion",
  OUTER_SHELL: "outerShell",
  INNER_SHELL: "innerShell",
  BOTTOM_SHELL: "bottomShell",
  FEET: "feet",
};

const getDefaultMaterials = (theme) => ({
  [MATERIAL_TYPES.LEGS]: {
    color: theme.primaryColor,
    roughness: 0.125,
    metalness: 0.25,
  },
  [MATERIAL_TYPES.FABRIC]: {
    color: theme.secondaryColor,
    roughness: 0.9,
    metalness: 0,
  },
  [MATERIAL_TYPES.CUSHION]: {
    color: theme.textColorLight,
    roughness: 0.65,
    metalness: 0.2,
  },
  [MATERIAL_TYPES.OUTER_SHELL]: {
    color: theme.primaryColor,
    roughness: 0.125,
    metalness: 0.25,
  },
  [MATERIAL_TYPES.BOTTOM_SHELL]: {
    color: theme.primaryColor,
    roughness: 0.125,
    metalness: 0.25,
  },
  [MATERIAL_TYPES.INNER_SHELL]: {
    color: theme.accentColorPrimary,
    roughness: 0.125,
    metalness: 0.25,
  },
  [MATERIAL_TYPES.FEET]: {
    color: theme.tertiaryColor,
    roughness: 0.8,
    metalness: 0.1,
  },
});

const useConfiguratorStore = create(
  devtools((set, get) => ({
    materials: {},

    updateMaterial: (type, properties) => {
      const material = get().materials[type];
      if (material) {
        Object.entries(properties).forEach(([key, value]) => {
          if (key === "color") {
            material.color.set(value);
          } else {
            material[key] = value;
          }
          material.needsUpdate = true;
        });
        set({ materials: { ...get().materials } });
      }
    },

    initMaterials: (theme) => {
      const defaults = getDefaultMaterials(theme);
      const materials = Object.entries(defaults).reduce(
        (acc, [type, props]) => {
          acc[type] = new MeshStandardMaterial({
            ...props,
            color: new Color(props.color),
          });
          return acc;
        },
        {}
      );
      set({ materials });
    },
  }))
);

export default useConfiguratorStore;
