import React, { useState, useCallback, useEffect } from "react";
import useStore from "../../stores/store";
import useConfiguratorStore from "./configuratorStore";
import {
  SlideOutLogoContainer,
  LogoWrapper,
  DetailsContainer,
  Content,
  MaterialControl,
  MaterialHeader,
  HeaderLeft,
  Arrow,
  MaterialName,
  ColorInput,
  MaterialContent,
  SliderGroup,
  SliderLabel,
  Slider,
} from "./ConfiguratorUI.styles";
import ExperienceSlideOut from "../../components/Experience/ExperienceSlideOut/ExperienceSlideOut";

const MaterialControls = ({ type, properties, onUpdate, isOpen, onToggle }) => {
  const getColorValue = (color) => {
    if (!color) return "#ffffff";
    return color.isColor ? "#" + color.getHexString() : color;
  };

  return (
    <MaterialControl>
      <MaterialHeader onClick={onToggle}>
        <HeaderLeft>
          <Arrow $isOpen={isOpen}>
            <svg viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth="2">
              <polyline points="6 9 12 15 18 9" />
            </svg>
          </Arrow>
          <MaterialName>
            {type.charAt(0).toUpperCase() + type.slice(1)}
          </MaterialName>
        </HeaderLeft>
        <ColorInput
          type="color"
          value={getColorValue(properties.color)}
          onChange={(e) => onUpdate(type, { color: e.target.value })}
          onClick={(e) => e.stopPropagation()}
        />
      </MaterialHeader>
      <MaterialContent $isOpen={isOpen}>
        {["Gloss", "Metalness"].map((prop) => (
          <SliderGroup key={prop}>
            <SliderLabel>{prop}</SliderLabel>
            <Slider
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={
                prop === "Gloss"
                  ? properties.roughness || 0
                  : properties.metalness || 0
              }
              onChange={(e) =>
                onUpdate(type, {
                  [prop === "Gloss" ? "roughness" : "metalness"]: parseFloat(
                    e.target.value
                  ),
                })
              }
            />
          </SliderGroup>
        ))}
      </MaterialContent>
    </MaterialControl>
  );
};

const ConfiguratorUI = () => {
  const { theme, isCollapsed, toggleCollapse, updateSlideOut } = useStore();
  const { materials, updateMaterial } = useConfiguratorStore();
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [hoveredMaterial, setHoveredMaterial] = useState(null);

  useEffect(() => {
    updateSlideOut(true, isCollapsed);
    return () => updateSlideOut(false, true);
  }, [isCollapsed, updateSlideOut]);

  const handleCollapse = useCallback(() => {
    if (!isCollapsed && selectedMaterial) {
      setSelectedMaterial(null);
    }
    toggleCollapse();
  }, [isCollapsed, selectedMaterial, toggleCollapse]);

  const renderContent = useCallback(
    ({ closeButton }) => (
      <>
        <SlideOutLogoContainer>
          {Object.keys(materials).map((type) => (
            <LogoWrapper
              key={type}
              $isActive={selectedMaterial?.type === type}
              $isHovered={hoveredMaterial === type}
              onClick={() => setSelectedMaterial({ type })}
              onMouseEnter={() => setHoveredMaterial(type)}
              onMouseLeave={() => setHoveredMaterial(null)}
            >
              {type.charAt(0).toUpperCase()}
            </LogoWrapper>
          ))}
        </SlideOutLogoContainer>

        <DetailsContainer $isCollapsed={isCollapsed}>
          <Content>
            {Object.entries(materials).map(([type, properties]) => (
              <MaterialControls
                key={type}
                type={type}
                properties={properties}
                onUpdate={updateMaterial}
                isOpen={selectedMaterial?.type === type}
                onToggle={() =>
                  setSelectedMaterial(
                    selectedMaterial?.type === type ? null : { type }
                  )
                }
              />
            ))}
          </Content>
        </DetailsContainer>
      </>
    ),
    [materials, selectedMaterial, hoveredMaterial, isCollapsed, updateMaterial]
  );

  return (
    <ExperienceSlideOut
      isCollapsed={isCollapsed}
      onCollapse={handleCollapse}
      selectedItem={selectedMaterial}
      onItemSelect={setSelectedMaterial}
      onBackClick={() => setSelectedMaterial(null)}
      showCloseButton={true}
      initialCollapsed={false}
    >
      {renderContent}
    </ExperienceSlideOut>
  );
};

export default ConfiguratorUI;
