// components/Experience/ExperienceSystem/ExperienceSystem.js

import React from "react";
import { SceneNames } from "../../../constants/sceneNames";
import ConfiguratorUI from "../../../experiences/Configurator/ConfiguratorUI";
import HexUI from "../../../experiences/HexWorld/HexUI/HexUI";
import MarbleRunUI from "../../../experiences/MarbleRun/MarbleRunUI/MarbleRunUI";

const EXPERIENCE_KEYS = {
  DODECAHEDRON: "Dodecahedron",
  HEX_CRAFT: "HexCraft",
  HEX_LEVEL: "HexLevel",
  CONFIGURATOR: "Configurator",
  MARBLE_RUN: "MarbleRun",
};

export const ExperienceRegistry = {
  [EXPERIENCE_KEYS.DODECAHEDRON]: {
    Scene: React.lazy(() => import("../../../experiences/Dodecahedron")),
    UI: null,
    key: "dodecahedron",
    transition: {
      interpolate: (progress) => {
        const angle = progress * Math.PI * 3;
        const radius = 3 * (1 - progress);

        return {
          scale: [1 - progress * 0.3, 1 - progress * 0.3, 1 - progress * 0.3],
          position: [
            Math.cos(angle) * radius,
            Math.sin(angle) * radius,
            -5 * progress,
          ],
          rotation: [0, angle, 0],
          opacity: 1 - progress,
        };
      },
      type: 4, // Use numeric type directly
      duration: 1500,
      speed: 0.03, // Custom speed for spiral
    },
  },
  [EXPERIENCE_KEYS.HEX_CRAFT]: {
    Scene: React.lazy(() => import("../../../experiences/HexWorld/HexCraft")),
    UI: <HexUI />,
    key: "hexcraft",
    transition: {
      interpolate: (progress) => ({
        scale: [progress, progress, progress],
        position: [0, 0, 10 * (1 - progress)],
        opacity: progress,
      }),
      type: 4, // Use numeric type directly
      duration: 1500,
      speed: 0.03, // Custom speed for spiral
    },
  },
  [EXPERIENCE_KEYS.HEX_LEVEL]: {
    Scene: React.lazy(() => import("../../../experiences/HexLevel")),
    UI: null,
    key: "hexlevel",
    transition: {
      interpolate: (progress) => ({
        scale: [progress, progress, progress],
        position: [0, 0, 10 * (1 - progress)],
        opacity: progress,
      }),
      type: 0, // Use numeric type directly
      duration: 1500,
      speed: 0.03, // Custom speed for spiral
    },
  },
  [EXPERIENCE_KEYS.CONFIGURATOR]: {
    Scene: React.lazy(
      () => import("../../../experiences/Configurator/ConfiguratorScene")
    ),
    UI: <ConfiguratorUI />,
    key: "configurator",
    transition: {
      interpolate: (progress) => ({
        scale: [progress, progress, progress],
        position: [0, 0, 0],
        opacity: progress,
      }),
      type: 0, // Fade
      duration: 800,
      speed: 0.03,
    },
  },
  [EXPERIENCE_KEYS.MARBLE_RUN]: {
    Scene: React.lazy(() => import("../../../experiences/MarbleRun/MarbleRun")),
    UI: <MarbleRunUI />,
    key: "marblerun",
    transition: {
      interpolate: (progress) => ({
        scale: [progress, progress, progress],
        position: [0, 0, 0],
        opacity: progress,
      }),
      type: 0, // Fade
      duration: 800,
      speed: 0.03,
    },
  },
};

const SCENE_TO_EXPERIENCE_MAP = {
  [SceneNames.DEFAULT]: EXPERIENCE_KEYS.DODECAHEDRON,
  [SceneNames.DODECAHEDRON]: EXPERIENCE_KEYS.DODECAHEDRON,
  [SceneNames.HEX_CRAFT]: EXPERIENCE_KEYS.HEX_CRAFT,
  [SceneNames.HEX_LEVEL]: EXPERIENCE_KEYS.HEX_LEVEL,
  [SceneNames.CONFIGURATOR]: EXPERIENCE_KEYS.CONFIGURATOR,
  [SceneNames.MARBLE_RUN]: EXPERIENCE_KEYS.MARBLE_RUN,
  hexcraft: EXPERIENCE_KEYS.HEX_CRAFT,
  hexlevel: EXPERIENCE_KEYS.HEX_LEVEL,
  configurator: EXPERIENCE_KEYS.CONFIGURATOR,
  marblerun: EXPERIENCE_KEYS.MARBLE_RUN,
  "projects/hexmap": EXPERIENCE_KEYS.HEX_CRAFT,
  "projects/hexlevel": EXPERIENCE_KEYS.HEX_LEVEL,
  "projects/configurator": EXPERIENCE_KEYS.CONFIGURATOR,
  "projects/marble-run": EXPERIENCE_KEYS.MARBLE_RUN,
  "game-dev": EXPERIENCE_KEYS.DODECAHEDRON,
};

export const getExperience = (scene, currentPath = "") => {
  if (process.env.NODE_ENV === "development") {
    console.log("Getting experience for:", { scene, currentPath });
  }

  // Normalize scene name
  const normalizedScene = scene?.toLowerCase();

  // Check path first
  if (currentPath) {
    const pathExperience = SCENE_TO_EXPERIENCE_MAP[currentPath];
    if (pathExperience && ExperienceRegistry[pathExperience]) {
      return {
        ...ExperienceRegistry[pathExperience],
        key: pathExperience.toLowerCase(),
      };
    }
  }

  // Then check scene mapping
  const sceneExperience = SCENE_TO_EXPERIENCE_MAP[normalizedScene];
  if (sceneExperience && ExperienceRegistry[sceneExperience]) {
    return {
      ...ExperienceRegistry[sceneExperience],
      key: sceneExperience.toLowerCase(),
    };
  }

  // Fallback to default
  return {
    ...ExperienceRegistry[EXPERIENCE_KEYS.DODECAHEDRON],
    key: EXPERIENCE_KEYS.DODECAHEDRON.toLowerCase(),
  };
};
