// constants/sceneExperiences.js
import { SceneNames } from "./sceneNames";

export const ExperienceComponents = {
  DODECAHEDRON: "Dodecahedron",
  CONFIGURATOR: "Configurator",
  HEX_CRAFT: "HexCraft",
  // Add other experiences here
};

// Map scenes to their corresponding experiences
export const SceneExperienceMap = {
  [SceneNames.DEFAULT]: ExperienceComponents.DODECAHEDRON,
  [SceneNames.HOME]: ExperienceComponents.DODECAHEDRON,
  [SceneNames.ABOUT]: ExperienceComponents.DODECAHEDRON,
  [SceneNames.PROJECTS]: ExperienceComponents.DODECAHEDRON,
  [SceneNames.CONFIGURATOR]: ExperienceComponents.CONFIGURATOR,
  [SceneNames.HEX_CRAFT]: ExperienceComponents.HEX_CRAFT,
  // Specific route mappings
  "projects/configurator": ExperienceComponents.CONFIGURATOR,
  "projects/hexmap": ExperienceComponents.HEX_CRAFT,
};

// Helper to get experience for a scene or route
export const getExperienceForScene = (scene, currentPath = "") => {
  // First check for exact route matches
  if (currentPath && SceneExperienceMap[currentPath]) {
    return SceneExperienceMap[currentPath];
  }

  // Then fall back to scene mapping
  return SceneExperienceMap[scene] || ExperienceComponents.DODECAHEDRON; // Explicit fallback to Dodecahedron
};
