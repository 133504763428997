// constants/sceneNames.js

export const SceneNames = {
  DEFAULT: "default",
  HOME: "home",
  ABOUT: "about",
  PROJECTS: "projects",
  EXPERIENCE: "EXPERIENCE",
  CONTACT: "CONTACT",
  CASE_STUDY: "CASE_STUDY",
  GAME_DEV: "GAME_DEV",

  // Experience-specific scenes
  DODECAHEDRON: "dodecahedron",
  CONFIGURATOR: "configurator",
  HEX_CRAFT: "hexcraft",
  HEX_LEVEL: "hexlevel",
  MARBLE_RUN: "marblerun",
  PORTRAITURE: "PORTRAITURE",
  FITNESS: "FITNESS",
  IMPRISONED: "IMPRISONED",
  PROJECT_OVERVIEW: "PROJECT_OVERVIEW",
  PROJECT_DETAIL: "PROJECT_DETAIL",
  SKILL_SHOWCASE: "SKILL_SHOWCASE",

  // Transition scenes or loading scenes
  LOADING: "LOADING",
  TRANSITION: "TRANSITION",

  // Error or fallback scenes
  ERROR: "ERROR",
  NOT_FOUND: "NOT_FOUND",
};

export const SceneNamesArray = Object.values(SceneNames);

// Function to check if a scene name is valid:
export const isValidSceneName = (sceneName) =>
  SceneNamesArray.includes(sceneName);
