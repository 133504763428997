import React, { useRef, useEffect, useState, useCallback } from "react";
import { useKeyboardControls } from "@react-three/drei";
import { addEffect } from "@react-three/fiber";
import useGame from "../MarbleRunStore";
import styled from "styled-components";

// Original styled components
const Interface = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  font-family: "Bebas Neue", cursive;
`;

const Time = styled.div`
  position: absolute;
  top: 15%;
  left: 0;
  width: 100%;
  color: #fff;
  font-size: 6vh;
  background: #00000033;
  padding-top: 5px;
  text-align: center;
`;

const RestartButton = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  top: 40%;
  left: 0;
  width: 100%;
  color: #fff;
  font-size: 80px;
  background: #00000033;
  padding-top: 10px;
  pointer-events: auto;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }
`;

const Controls = styled.div`
  position: absolute;
  bottom: 10%;
  left: 0;
  width: 100%;
`;

const ControlsRow = styled.div`
  display: flex;
  justify-content: center;
  margin: 4px 0;
`;

const Key = styled.div`
  width: 40px;
  height: 40px;
  margin: 4px;
  border: 2px solid #ffffff;
  background: #ffffff44;
  color: white;
  text-align: center;
  align-content: center;

  &.large {
    width: 144px;
  }
`;

// Mobile control styled components
const MobileControls = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  pointer-events: none;
  z-index: 50;
`;

const MobileControlsLayout = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  max-width: 500px;
  margin: 0 1rem 1rem 3rem;
`;

const Joystick = styled.div`
  position: relative;
  width: 128px;
  height: 128px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  pointer-events: auto;
  touch-action: none;
`;

const JoystickKnob = styled.div`
  position: absolute;
  width: 64px;
  height: 64px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  transition: ${(props) => (props.$isPressed ? "none" : "all 0.2s ease-out")};
`;

const JumpButton = styled.button`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  border: 2px solid #ffffff;
  color: white;
  pointer-events: auto;
  font-family: "Bebas Neue", cursive;
  font-size: 20px;

  &:active {
    background-color: rgba(255, 255, 255, 0.3);
  }
`;

export default function MarbleRunUI() {
  const time = useRef();
  const restart = useGame((state) => state.restart);
  const phase = useGame((state) => state.phase);

  const forward = useKeyboardControls((state) => state.forward);
  const backward = useKeyboardControls((state) => state.backward);
  const leftward = useKeyboardControls((state) => state.leftward);
  const rightward = useKeyboardControls((state) => state.rightward);
  const jump = useKeyboardControls((state) => state.jump);

  // Mobile controls state
  const [isMobile, setIsMobile] = useState(false);
  const [joystickPos, setJoystickPos] = useState({ x: 0, y: 0 });
  const [isPressed, setIsPressed] = useState(false);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [knobPos, setKnobPos] = useState({ x: 0, y: 0 });

  // Original timer effect
  useEffect(() => {
    const unsubscribeEffect = addEffect(() => {
      const state = useGame.getState();

      let elapsedTime = 0;

      if (state.phase === "playing") {
        elapsedTime = Date.now() - state.startTime;
      } else if (state.phase === "ended") {
        elapsedTime = state.endTime - state.startTime;
      }

      elapsedTime /= 1000;
      elapsedTime = elapsedTime.toFixed(2);

      if (time.current) {
        time.current.textContent = elapsedTime;
      }
    });

    return () => {
      unsubscribeEffect();
    };
  }, []);

  // Mobile detection
  useEffect(() => {
    const checkMobile = () => {
      const isMobileDevice =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        );
      setIsMobile(isMobileDevice);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  // Mobile controls handlers
  useEffect(() => {
    if (window.gameControls) {
      window.gameControls.joystick = joystickPos;
    } else {
      window.gameControls = { joystick: joystickPos };
    }
  }, [joystickPos]);

  const handleTouchStart = useCallback((e) => {
    e.preventDefault();
    const touch = e.touches[0];
    const rect = e.target.getBoundingClientRect();
    const x = touch.clientX - rect.left;
    const y = touch.clientY - rect.top;

    setIsPressed(true);
    setStartPos({ x, y });
    setKnobPos({ x: 0, y: 0 });
  }, []);

  const handleTouchMove = useCallback(
    (e) => {
      e.preventDefault();
      if (!isPressed) return;

      const touch = e.touches[0];
      const rect = e.target.getBoundingClientRect();
      const x = touch.clientX - rect.left;
      const y = touch.clientY - rect.top;

      const deltaX = x - startPos.x;
      const deltaY = y - startPos.y;

      const distance = Math.min(
        50,
        Math.sqrt(deltaX * deltaX + deltaY * deltaY)
      );
      const angle = Math.atan2(deltaY, deltaX);

      const limitedX = distance * Math.cos(angle);
      const limitedY = distance * Math.sin(angle);

      setKnobPos({ x: limitedX, y: limitedY });

      const normalizedX = limitedX / 50;
      const normalizedY = limitedY / 50;

      setJoystickPos({ x: normalizedX, y: normalizedY });
    },
    [isPressed, startPos]
  );

  const handleTouchEnd = useCallback((e) => {
    e.preventDefault();
    setIsPressed(false);
    setKnobPos({ x: 0, y: 0 });
    setJoystickPos({ x: 0, y: 0 });
  }, []);

  const handleJump = useCallback((e) => {
    e.preventDefault();
    if (window.gameControls) {
      window.gameControls.jump = true;
      setTimeout(() => {
        window.gameControls.jump = false;
      }, 100);
    }
  }, []);

  return (
    <>
      <Interface>
        <Time ref={time}>0.00</Time>

        {phase === "ended" && (
          <RestartButton onClick={restart}>Restart</RestartButton>
        )}

        {!isMobile && (
          <Controls>
            <ControlsRow>
              <Key $active={forward}>W</Key>
            </ControlsRow>
            <ControlsRow>
              <Key $active={leftward}>A</Key>
              <Key $active={backward}>S</Key>
              <Key $active={rightward}>D</Key>
            </ControlsRow>
            <ControlsRow>
              <Key $active={jump} className="large">
                Spacebar
              </Key>
            </ControlsRow>
          </Controls>
        )}
      </Interface>

      {isMobile && (
        <MobileControls>
          <MobileControlsLayout>
            <Joystick
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
            >
              <JoystickKnob
                $isPressed={isPressed}
                style={{
                  transform: `translate(-50%, -50%) translate(${knobPos.x}px, ${knobPos.y}px)`,
                }}
              />
            </Joystick>
            <JumpButton onTouchStart={handleJump}>JUMP</JumpButton>
          </MobileControlsLayout>
        </MobileControls>
      )}
    </>
  );
}
