import create from "zustand";
import { resources } from "./Blocks";

// Initialize default resource parameters
const defaultResourceParams = {};
resources.forEach((resource) => {
  defaultResourceParams[resource.name] = {
    scale: resource.scale || { x: 30, y: 30, z: 30 },
    scarcity: resource.scarcity || 0.5,
  };
});

const useHexWorldStore = create((set, get) => ({
  worldParams: {
    size: {
      radius: 32,
      height: 32,
    },
    params: {
      seed: Math.floor(Math.random() * 100000),
      terrain: {
        scale: 30,
        magnitude: 0.5,
        offset: 0.2,
      },
      resources: defaultResourceParams,
    },
  },

  updateSize: (size) => {
    set((state) => ({
      worldParams: {
        ...state.worldParams,
        size: {
          ...state.worldParams.size,
          ...size,
        },
      },
    }));
  },

  updateTerrainParams: (terrainParams) => {
    set((state) => ({
      worldParams: {
        ...state.worldParams,
        params: {
          ...state.worldParams.params,
          terrain: {
            ...state.worldParams.params.terrain,
            ...terrainParams,
          },
        },
      },
    }));
  },

  updateSeed: (seed) => {
    set((state) => ({
      worldParams: {
        ...state.worldParams,
        params: {
          ...state.worldParams.params,
          seed,
        },
      },
    }));
  },

  updateResourceParams: (resourceName, params) => {
    set((state) => ({
      worldParams: {
        ...state.worldParams,
        params: {
          ...state.worldParams.params,
          resources: {
            ...state.worldParams.params.resources,
            [resourceName]: {
              ...state.worldParams.params.resources[resourceName],
              ...params,
            },
          },
        },
      },
    }));
  },

  regenerateWorld: () => {
    set((state) => ({
      worldParams: {
        ...state.worldParams,
        params: {
          ...state.worldParams.params,
          seed: Math.floor(Math.random() * 100000),
        },
      },
    }));
  },
}));

export default useHexWorldStore;
