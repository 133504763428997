import React, { useState, useMemo, useCallback, useEffect } from "react";
import useStore from "../../../stores/store";
import useHexWorldStore from "../hexWorldStore";
import { resources } from "../Blocks";
import { SkeletonTheme } from "react-loading-skeleton";
import HexSlideOut from "../HexSlideOut";
import {
  SlideOutLogoContainer,
  LogoWrapper,
  DetailsContainer,
  CategoryHeader,
  ControlPanel,
  Control,
  Label,
  Value,
  Slider,
  RegenerateButton,
  HeaderContent,
} from "./HexUI.styles";

const formatResourceName = (name) =>
  name
    .replace(/([A-Z])/g, " $1")
    .trim()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

const HexUI = () => {
  const { theme, isCollapsed, toggleCollapse, updateSlideOut } = useStore();
  const worldParams = useHexWorldStore((state) => state.worldParams);
  const {
    updateSize,
    updateTerrainParams,
    updateSeed,
    updateResourceParams,
    regenerateWorld,
  } = useHexWorldStore();

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [hoveredCategory, setHoveredCategory] = useState(null);

  useEffect(() => {
    updateSlideOut(true, isCollapsed);
    return () => updateSlideOut(false, true);
  }, [isCollapsed, updateSlideOut]);

  const categories = useMemo(
    () => [
      { id: "map", title: "Map", icon: "Map" },
      ...resources.map((resource) => ({
        id: `resource-${resource.name}`,
        title: formatResourceName(resource.name),
        icon: formatResourceName(resource.name).substring(0, 3),
        resourceData: resource,
      })),
    ],
    []
  );

  const handleCategoryClick = useCallback(
    (category) => {
      setSelectedCategory(category);
      if (isCollapsed) toggleCollapse();
    },
    [isCollapsed, toggleCollapse]
  );

  const handleBackClick = useCallback(() => setSelectedCategory(null), []);

  const handleCollapse = useCallback(() => {
    if (!isCollapsed && selectedCategory) setSelectedCategory(null);
    toggleCollapse();
  }, [isCollapsed, selectedCategory, toggleCollapse]);

  const renderMapControls = useCallback(
    () => (
      <>
        <Control>
          <Label>
            World Radius <Value>{worldParams.size.radius}</Value>
          </Label>
          <Slider
            type="range"
            min="8"
            max="64"
            step="1"
            value={worldParams.size.radius}
            onChange={(e) => updateSize({ radius: parseInt(e.target.value) })}
          />
        </Control>
        <Control>
          <Label>
            World Height <Value>{worldParams.size.height}</Value>
          </Label>
          <Slider
            type="range"
            min="1"
            max="128"
            step="1"
            value={worldParams.size.height}
            onChange={(e) => updateSize({ height: parseInt(e.target.value) })}
          />
        </Control>
        <Control>
          <Label>
            Terrain Seed <Value>{worldParams.params.seed}</Value>
          </Label>
          <Slider
            type="range"
            min="0"
            max="99999"
            step="1"
            value={worldParams.params.seed}
            onChange={(e) => updateSeed(parseInt(e.target.value))}
          />
        </Control>
        <Control>
          <Label>
            Terrain Scale <Value>{worldParams.params.terrain.scale}</Value>
          </Label>
          <Slider
            type="range"
            min="10"
            max="100"
            step="1"
            value={worldParams.params.terrain.scale}
            onChange={(e) =>
              updateTerrainParams({ scale: parseFloat(e.target.value) })
            }
          />
        </Control>
        <Control>
          <Label>
            Terrain Magnitude{" "}
            <Value>{worldParams.params.terrain.magnitude}</Value>
          </Label>
          <Slider
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={worldParams.params.terrain.magnitude}
            onChange={(e) =>
              updateTerrainParams({ magnitude: parseFloat(e.target.value) })
            }
          />
        </Control>
        <Control>
          <Label>
            Terrain Offset <Value>{worldParams.params.terrain.offset}</Value>
          </Label>
          <Slider
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={worldParams.params.terrain.offset}
            onChange={(e) =>
              updateTerrainParams({ offset: parseFloat(e.target.value) })
            }
          />
        </Control>
      </>
    ),
    [worldParams, updateSize, updateSeed, updateTerrainParams]
  );

  const renderResourceControls = useCallback(
    (resource) => (
      <>
        {["x", "y", "z"].map((axis) => (
          <Control key={axis}>
            <Label>
              Scale {axis.toUpperCase()}
              <Value>
                {worldParams.params.resources[resource.name]?.scale?.[axis] ||
                  30}
              </Value>
            </Label>
            <Slider
              type="range"
              min="10"
              max="100"
              step="1"
              value={
                worldParams.params.resources[resource.name]?.scale?.[axis] || 30
              }
              onChange={(e) =>
                updateResourceParams(resource.name, {
                  scale: {
                    ...worldParams.params.resources[resource.name]?.scale,
                    [axis]: parseFloat(e.target.value),
                  },
                })
              }
            />
          </Control>
        ))}
        <Control>
          <Label>
            Scarcity
            <Value>
              {worldParams.params.resources[resource.name]?.scarcity || 0.5}
            </Value>
          </Label>
          <Slider
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={worldParams.params.resources[resource.name]?.scarcity || 0.5}
            onChange={(e) =>
              updateResourceParams(resource.name, {
                scarcity: parseFloat(e.target.value),
              })
            }
          />
        </Control>
      </>
    ),
    [worldParams, updateResourceParams]
  );

  const renderCategoryContent = useCallback(
    (category) => {
      if (!category) return null;

      return (
        <ControlPanel>
          {category.id === "map"
            ? renderMapControls()
            : category.id.startsWith("resource-")
              ? renderResourceControls(category.resourceData)
              : null}
          <RegenerateButton onClick={regenerateWorld}>
            Regenerate World
          </RegenerateButton>
        </ControlPanel>
      );
    },
    [renderMapControls, renderResourceControls, regenerateWorld]
  );

  const renderContent = useCallback(
    ({ closeButton }) => (
      <>
        <SlideOutLogoContainer>
          {categories.map((category) => (
            <LogoWrapper
              key={category.id}
              $isActive={selectedCategory?.id === category.id}
              $isHovered={hoveredCategory === category.id}
              onClick={() => handleCategoryClick(category)}
              onMouseEnter={() => setHoveredCategory(category.id)}
              onMouseLeave={() => setHoveredCategory(null)}
            >
              {category.icon}
            </LogoWrapper>
          ))}
        </SlideOutLogoContainer>

        <DetailsContainer $isCollapsed={isCollapsed}>
          {selectedCategory ? (
            <>
              <CategoryHeader
                $isActive={true}
                $isHovered={hoveredCategory === selectedCategory.id}
              >
                <HeaderContent>
                  <h2>{selectedCategory.title}</h2>
                  {closeButton}
                </HeaderContent>
              </CategoryHeader>
              {renderCategoryContent(selectedCategory)}
            </>
          ) : (
            categories.map((category) => (
              <CategoryHeader
                key={category.id}
                $isActive={selectedCategory?.id === category.id}
                $isHovered={hoveredCategory === category.id}
                onClick={() => handleCategoryClick(category)}
                onMouseEnter={() => setHoveredCategory(category.id)}
                onMouseLeave={() => setHoveredCategory(null)}
              >
                <h2>{category.title}</h2>
              </CategoryHeader>
            ))
          )}
        </DetailsContainer>
      </>
    ),
    [
      categories,
      selectedCategory,
      hoveredCategory,
      isCollapsed,
      handleCategoryClick,
      renderCategoryContent,
    ]
  );

  return (
    <SkeletonTheme
      baseColor={theme.primaryColor}
      highlightColor={theme.iconColorSecondary}
    >
      <HexSlideOut
        isCollapsed={isCollapsed}
        onCollapse={handleCollapse}
        selectedItem={selectedCategory}
        onItemSelect={handleCategoryClick}
        onBackClick={handleBackClick}
        showCloseButton={true}
      >
        {renderContent}
      </HexSlideOut>
    </SkeletonTheme>
  );
};

export default HexUI;
