import React from "react";
import {
  CareerSummaryContent,
  CareerSummaryContentWrapper,
} from "./CareerSummary.styles";

function CareerSummary() {
  return (
    <CareerSummaryContentWrapper disableBackground={true}>
      <h2>What I Do</h2>
      <CareerSummaryContent>
        <p>
          I help creatives, craftsmen, and scrappy entrepreneurs find their
          tribes, hone their MVPs, and create durable, profitable,
          self-sustaining businesses.
        </p>
      </CareerSummaryContent>
    </CareerSummaryContentWrapper>
  );
}

export default CareerSummary;
