import React from "react";
import {
  ProjectSummaryContent,
  ProjectSummaryContentWrapper,
} from "./ProjectSummary.styles";

function ProjectSummary() {
  return (
    <ProjectSummaryContentWrapper disableBackground={true}>
      <h2>About This Place:</h2>
      <ProjectSummaryContent>
        <p>Alright, you've scrolled this far. Time for some real talk.</p>
        <p>Two things...</p>
        <p>First, there's no pitch waiting for you here.</p>
        <p>No funnel, no upsell, no cleverly disguised call-to-action.</p>
        <p>Second, this place is my workshop. My laboratory. My sandbox.</p>
        <p>
          Imagine walking into a creator's garage — tools scattered about,
          half-finished projects on every surface, ideas scribbled on
          whiteboards, etc. That's what you've stepped into here.
        </p>
        <p>
          This site is in <strong>active</strong> development.
        </p>
        <p>
          Three years ago, I took a hard left turn. After two decades of
          swimming in the marketing, communications, and design pool, I dove
          headfirst into the deep end of computer science, software development,
          and game creation.
        </p>
        <p>It wasn't just a career shift; it was a paradigm shift.</p>
        <p>And guess what?</p>
        <p>That shift is still happening. Right now. Every. Single. Day.</p>
        <p>
          Yes, this site has its rough, unfinished spots. You'll find unpolished
          corners and designs in mid-evolution (like the page you're reading
          now).
        </p>
        <p>Let the jank be part of the charm.</p>
      </ProjectSummaryContent>
    </ProjectSummaryContentWrapper>
  );
}

export default ProjectSummary;
