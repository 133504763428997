import React from "react";
import useStore from "../../stores/store";
import { StyledAnchor, StyledButton, StyledLink } from "./Button.styles";

function Button({
  children,
  to,
  href,
  onClick,
  disabled,
  transitionTo,
  transitionType,
  ...props
}) {
  const startTransition = useStore((state) => state.startTransition);
  const { theme } = useStore();

  const handleClick = async (e) => {
    if (disabled) return;

    if (onClick) {
      onClick(e);
    }

    if (transitionTo) {
      e.preventDefault();
      startTransition(transitionTo, transitionType);
    }
  };

  const commonProps = {
    $disabled: disabled,
    theme,
    onClick: handleClick,
    ...props,
  };

  if (to) {
    return (
      <StyledLink to={to} {...commonProps}>
        {children}
      </StyledLink>
    );
  }

  if (href) {
    return (
      <StyledAnchor href={href} {...commonProps}>
        {children}
      </StyledAnchor>
    );
  }

  return (
    <StyledButton type="button" disabled={disabled} {...commonProps}>
      {children}
    </StyledButton>
  );
}

Button.defaultProps = {
  disabled: false,
  transitionType: "fade",
};

export default Button;
